import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './store'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import axios from 'axios'
import router from './router'
import App from './App.vue'
Vue.prototype.$axios = axios // 等号前的$axios可以换成其他名称，不过使用要一致
Vue.config.productionTip = false
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VideoPlayer)

// 跳转后滚动条在最上面
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
  // chrome
  document.body.scrollTop = 0
// firefox
  document.documentElement.scrollTop = 0
// safari window.pageYOffset = 0
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
