<template>
  <div>
    <b-navbar toggleable="lg" style="background-color: #f4f8fb" :style="loadStylesNH" fixed="top">

      <b-navbar-brand>
        <img src="../assets/logo.jpg" class="img-responsive" alt="Responsive image" style="height:40px;"/>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="mt-5">

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" style="font-size: 17px;min-width:100px">
          <b-nav-item href="#">
            <b-icon icon="house-fill" variant="dark"></b-icon>
            <router-link :to="{name:'home'}"><a>  首页</a></router-link>
          </b-nav-item>
          <b-nav-item-dropdown text="云智服务" v-b-hover="callback" style="min-width:50px">
            <div style="height: 15px"/>
            <div v-for="(item) in navigationItems1" :key="item.name">
              <hr style="margin: 0px 0px; padding: 0px 0px; border-color: black">
              <b-dropdown-item @click="about(item)">{{
                  item.name
                }}
              </b-dropdown-item>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="云智动态">
            <div style="height: 15px"/>
            <div v-for="(item) in navigationItems2" :key="item.name">
              <hr style="margin: 0px 0px; padding: 0px 0px; border-color: black">
              <b-dropdown-item @click="about(item)">{{
                  item.name
                }}
              </b-dropdown-item>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="关于我们">
            <div style="height: 15px"/>
            <div v-for="(item) in navigationItems3" :key="item.name">
              <hr style="margin: 0px 0px; padding: 0px 0px; border-color: black">
              <b-dropdown-item @click="about(item)">{{
                  item.name
                }}
              </b-dropdown-item>
            </div>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'navigation_header',
  data () {
    return {
      navigationItems1: [{
        name: '云智咨询',
        href: 'ServerConsult',
        id: 1,
        temp: 2,
        order: 0
      }, {
        name: '云智基地',
        href: 'ServerBase',
        id: 1,
        temp: 2,
        order: 1
      }, {
        name: '云智学院',
        href: 'ServerCollege',
        id: 1,
        temp: 2,
        order: 2
      }],
      navigationItems2: [{
        name: '集团动态',
        href: 'TrendsGroup',
        id: 2,
        temp: 3,
        order: 0
      }, {
        name: '大赛活动',
        href: 'TrendsGroup',
        id: 2,
        temp: 3,
        order: 1
      }],
      navigationItems3: [{
        name: '集团简介',
        href: 'AboutUs',
        id: 3,
        temp: 1,
        order: 0
      }, {
        name: '联系方式',
        href: 'AboutUs',
        id: 3,
        temp: 1,
        order: 1
      }],
    }
  },
  props: {
    loadStylesNH: {
      type: String,
      required: true
    }
  },
  created () {
    // eslint-disable-next-line no-unused-expressions
    document.getElementsByClassName('nav-item').length
  },
  methods: {
    callback (result) {
      // alert(result)
    },
    about (item) {
      router.push({
        name: item.href,
        query: {
          id: item.id,
          order: item.order
        }
      })
    }
  }
}
</script>

<style scoped>

a:hover {
  text-decoration: none;
}

.nav-item {
  border-right: 2px black solid;
  padding: 0px 13px;
}

.nav-item a {
  /*color: red;*/
  font-size: 16px;
}

.nav-item /deep/ .dropdown-menu {
  min-width: 7.75rem;
  border: 0px;
  margin-top: 0px;
}

.nav-item /deep/ > a > a {
  color: black;
}

.nav-item /deep/ > a > span {
  color: black;
}

.nav-item /deep/ ul {
  align-content: center;
  padding: 0px 0px;
}

.nav-item /deep/ ul li {
  font-weight: normal;
  align-content: center;
  padding: 6px 0px;
  /*transition: padding-right 1s;*/
  /*-webkit-transition: padding-right 1s; !* Safari *!*/
}

.nav-item:hover /deep/ .dropdown-menu {
  display: block;
}
.nav-item /deep/ ul div:hover:not(:first-child){
  background-color: #01204f;
}
.nav-item /deep/ ul li:hover{
  background-color: #01204f;
  color: white;
  transform: translateX(10px);
  /* 0.4s完成transform移动效果*/
  transition: transform 0.2s;
}

.nav-item /deep/ ul li:hover a{
  background-color: #01204f;
  color: white;
  font-weight: bold;
}

.nav-item /deep/ ul li a:hover {
  background-color: #01204f;
  color: white;
}
</style>
