<template>
  <div>
    <b-nav align="right"
           class="navClass1"
           :style="loadStylesNH">
      <b-nav-item>
        <img src="../assets/logo.jpg" class="img-responsive img" alt="Responsive image"/>
      </b-nav-item>
      <b-nav-item @click="nav22(0)" v-on:mouseover="nav22(0)">
        <router-link :to="{name:'home'}" :style="clickClass[0]"><a>首页</a></router-link>
      </b-nav-item>
      <b-nav-item @click="nav22(1)" v-on:mouseover="nav22(1)">
        <div :style="clickClass[1]"><a>云智服务</a></div>
      </b-nav-item>
      <b-nav-item @click="nav22(2)" v-on:mouseover="nav22(2)">
        <div :style="clickClass[2]"><a>云智动态</a></div>
      </b-nav-item>
      <b-nav-item @click="nav22(3)" v-on:mouseover="nav22(3)">
        <div :style="clickClass[3]"><a>关于我们</a></div>
      </b-nav-item>
    </b-nav>
    <b-nav align="right" class="navClass2" :style="navShow" @mouseleave="leave">
      <b-nav-item v-for="(item) in navigationItems0" :key="item.name" active>
        <div class="navClass22" :style="loadStylesNH2" @click="open(item)">
          <div :style="clickClassNV2[item.order]">{{ item.name }}</div>
        </div>
      </b-nav-item>
    </b-nav>

  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'NavigationHeader',
  data() {
    return {
      index: 0,
      navigationItems0: [],
      navigationItems1: [{
        name: '云智咨询',
        href: 'ServerConsult',
        id: 1,
        order: 0
      }, {
        name: '云智基地',
        href: 'ServerBase',
        id: 1,
        order: 1
      }, {
        name: '云智学院',
        href: 'ServerCollege',
        id: 1,
        order: 2
      }],
      navigationItems2: [{
        name: '集团动态',
        href: 'TrendsGroup',
        id: 2,
        order: 0
      }, {
        name: '大赛活动',
        href: 'TrendsGroup',
        id: 2,
        order: 1
      }],
      navigationItems3: [{
        name: '集团简介',
        href: 'AboutUs',
        id: 3,
        order: 0
      }, {
        name: '联系方式',
        href: 'AboutUs',
        id: 3,
        order: 1
      }],
      navShow: 'display: none;',
      loadStylesNH2: '',
      clickClass: [],
      clickClassNV2: [],
    }
  },
  props: {
    loadStylesNH: {
      type: String,
      required: true
    },
    name: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
  },
  created() {
    // eslint-disable-next-line no-unused-expressions
    // document.getElementsByClassName('nav-item').length
    console.log(this.$route.query)
    console.log(this.$route.query)
    console.log(this.$route.query)
    console.log(this.$route.query)
    if(this.$route.query.id != undefined && this.$route.query.id != null
        && this.$route.query.name != '集团动态' && this.$route.query.name != '大赛活动'){
      this.nav22(this.$route.query.id)
      this.about(this.$route.query.order)
    }else {
      this.nav22(0)
    }
  },
  methods: {
    leave(){
      this.navShow = 'display: none;'
    },
    // 一级导航栏点击事件；刷新二级导航栏点击状态
    nav22(value){
      this.nav2(value)
      for(var i = 0; i < this.navigationItems0.length; i++){
        this.clickClassNV2[i] = 'color: black;border: 0px;border-radius: 0px;'
      }
      if(value ==  this.$route.query.id)
        this.about(this.$route.query.order)
    },
    // 一级导航栏更改样式事件
    nav2(value) {
      for(var i = 0; i < 4; i++){
        this.clickClass[i] = 'color: black'
      }
      this.clickClass[value] = 'color: #4b6bb5'
      if (value == 0) {
        this.navigationItems0 = []
        this.navShow = 'display: none;'
      } else if (value == 1) {
        this.navigationItems0 = this.navigationItems1
        this.navShow = 'display: flex;'
        this.loadStylesNH2 = 'left: -12.9vw;'
      } else if (value == 2) {
        this.navigationItems0 = this.navigationItems2
        this.navShow = 'display: flex;'
        this.loadStylesNH2 = 'left: -7.5vw;'
      } else if (value == 3) {
        this.navigationItems0 = this.navigationItems3
        this.navShow = 'display: flex;'
        this.loadStylesNH2 = 'left: -2.2vw;'
      }else {
        this.navShow = 'display: flex;'
      }
    },
    callback(result) {
      // alert(result)
    },
    about(id) {
      for(var i = 0; i < this.navigationItems0.length; i++){
        this.clickClassNV2[i] = 'color: black;border: 0px;border-radius: 0px;'
      }
      this.clickClassNV2[id] = 'color: black;border: 0.132vw solid transparent;'
      // 重新加载才生效？
      // this.nav2(item.temp)
      // this.nav2(item.id)
    },
    open(item){
      router.push({
        name: item.href,
        query: {
          id: item.id,
          order: item.order
        }
      }, () => {})
    }
  }
}
</script>

<style lang="less" scoped>
/*导航栏1*/
.navClass1 {
  position: fixed;
  top: 0px;
  z-index: 1000;
  background-color: #f4f8fb;
  height: 4vw;
  line-height: 4vw;
  width: 99vw
}
.nav:first-child .nav-item {
  /*border-right: 2px black solid;*/
  padding: 0px 3.922vw
  /*width: 180px;*/
}
//固定图标位置
.nav:first-child .nav-item:first-child {
  position: absolute;
  left: 3.268vw;
}
//只有导航栏1悬浮变色
//.nav:first-child .nav-item a :hover {
//  color: #4b6bb5;
//}

/*导航栏2*/
.navClass2 {
  position: fixed;
  top: 3.922vw;
  z-index: 1000;
  background-color: #f0f3f8;
  height: 3.922vw;
  line-height: 3.922vw;
  width: 99vw;
  padding: 0px 5%;
}
.navClass22{
  position: relative;
  left: -9.085vw;
  right: 0px;
  margin: 0vw 0.654vw
}
.nav:last-child {
  float: right;
}
.nav:last-child .nav-item{
  line-height: 3.922vw;
  margin: auto 0px;
}
.nav:last-child .nav-item a div{
  //border: 1px solid;
  //border-image: linear-gradient(to right, #8f41e9, #578aef) 1;
  font-size: 0.915vw;
  line-height: 1.307vw;
  padding: 0.261vw 0.784vw;
  margin: auto 0px;
  /*width: 180px;*/
}
.nav:last-child .nav-item a div div{
  border: 0px solid transparent;
  border-radius: 0.331vw;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #f0f3f8, #f0f3f8), linear-gradient(to right, #c0da9c, #5c74b3);
}

/*其他*/
.nav-item a {
  /*color: red;*/
  font-size: 1.046vw;
  padding: 0px 0px;
  //color: black;
  font-weight: bold;
  :hover {
    cursor:pointer;
  }
}
a:hover {
  text-decoration: none;
}
// 取消按钮默认悬浮样式
a:hover {
  cursor:default;
}

.img {
  height:3vw;
}

</style>
