<template>
  <div>
    <!--导航栏-->
    <NavigationHeader :loadStylesNH="loadStylesNH" v-if="this.selectNH == 1"/>
    <NavigationHeader2 :loadStylesNH="loadStylesNH" v-else/>
    <div class="home" :style="home">
      <div class="home1" :style="home1">
        <!--      <div style="height: 190px"/>-->
        <RotationImage :loadStylesRI="loadStylesRI"/>
        <div style="margin: 7.5% 1% 0% 1%" :style="rotationTextDiv">
          <b-row style="font-size: 16px;background: linear-gradient(to right, #F9FAFC, #FFFFFF, #F9FAFC)" :style="rotationText">
            <b-col cols="2" style="text-align: center">
              <div class="zxdt" :style="rotationText1">最新动态</div>
            </b-col>
            <b-col cols="8" style="text-align: center">
              <marquee-text :duration="55" v-on:mouseenter="updatePausedTrue" v-on:mouseleave="updatePausedFalse" :paused="paused">
                  <span v-for="(item) in news" :key="item.idpk" class="newsClass" :style="rotationText2" :id="item.title + 1" @click="openNewsDetail(item)">
                    <b-icon icon="circle-fill" variant="dark" style="width: 5px;height: 5px;margin:0px 10px 5px 5px"></b-icon>
                     <span style="padding-right: 25px">{{ item.title }}</span>  <span>{{ item.issueTime }}</span>
                  </span>
              </marquee-text>
            </b-col>
            <b-col cols="2" style="text-align: center" class="more">
              <div class="zxdt" :style="rotationText1" @click="openNewsList">
                <div>MORE ></div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!--内容页-->
      <div class="home2" :style="home2">
        <div class="content" :style="content">
          <div v-on:mouseover="newImg(0)" v-on:mouseout="oldImg(0)">
            <b-img
                fluid
                :src="require('../assets/home/05.jpg')"
                alt="banner"
                :width="imgWidth"
                v-if="this.imgShow[0]"
            />
            <b-img
                fluid
                :src="require('../assets/home/005.jpg')"
                alt="banner"
                :width="imgWidth"
                class="imgClick"
                v-on:click="openServer(1)"
                v-else
            />
          </div>
          <div v-on:mouseover="newImg(1)" v-on:mouseout="oldImg(1)">
            <b-img
                fluid
                :src="require('../assets/home/06.jpg')"
                alt="banner"
                :width="imgWidth"
                v-if="this.imgShow[1]"
            />
            <b-img
                fluid
                :src="require('../assets/home/006.jpg')"
                alt="banner"
                :width="imgWidth"
                class="imgClick"
                v-on:click="openServer(2)"
                v-else
            />
          </div>
          <div v-on:mouseover="newImg(2)" v-on:mouseout="oldImg(2)">
            <b-img
                fluid
                :src="require('../assets/home/07.jpg')"
                alt="banner"
                :width="imgWidth"
                v-if="this.imgShow[2]"
            />
            <b-img
                fluid
                :src="require('../assets/home/007.jpg')"
                alt="banner"
                :width="imgWidth"
                class="imgClick"
                v-on:click="openServer(3)"
                v-else
            />
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import {
  listPortalnewsAll,
  getPortalnews
} from '@/api/portal/portalnews'
import NavigationHeader from '@/components/NavigationHeader.vue'
import NavigationHeader2 from '@/components/NavigationHeader2.vue'
import RotationImage from '@/components/RotationImage.vue'
import Footer from '@/components/Footer.vue'
import MarqueeText from 'vue-marquee-text-component'
import router from "@/router";

export default {
  name: 'HomeView',
  components: {
    // HelloWorld,
    NavigationHeader,
    NavigationHeader2,
    RotationImage,
    Footer,
    MarqueeText,
  },
  data () {
    return {
      situation: '',
      news: [],
      rotationTextDiv: '',
      rotationText: '',
      rotationText1: '',
      rotationText2: '',
      loadStylesFoot: '',
      loadStylesNH: '',
      loadStylesRI: '',
      selectNH: 1,
      home: '',
      home1: '',
      home2: '',
      content: '',
      imgWidth: '400px',
      imgShow: [true, true, true],
      paused: false,
    }
  },
  mounted () {
    // window.addEventListener('scroll', this.handleScroll); //添加监听事件
    const _this = this
    // alert(document.documentElement.clientWidth)
    // 页面整体内边距调整
    // 同一页面 只会有一个生效 都写在父组件维护
    // console.log(document.documentElement.clientWidth)
    this.updateSize()
    window.onresize = function () {
      // console.log(document.documentElement.clientWidth)
      _this.updateSize()
    }
  },
  created () {
    let query = {
      pageNum: 1,
      pageSize: 3,
      columnId: 8
    }
    listPortalnewsAll(query).then((response) => {
      this.news = response.rows
    })
    // getPortalnews(13).then((response) => {
    //   this.situation = response.data.h5Content
    // })
  },
  methods: {
    openServer(index){
      if(index == 1){
        router.push({
          name: 'ServerConsult',
          query: {
            name: '云智咨询'
          }
        }, () => {})
      }else if(index == 2){
        router.push({
          name: 'ServerBase',
          query: {
            name: '云智基地'
          }
        }, () => {})
      }else if(index == 3){
        router.push({
          name: 'ServerCollege',
          query: {
            name: '云智学院'
          }
        }, () => {})
      }
    },
    openNewsList(){
      router.push({
        name: 'NewsList',
        query: {
          name: '集团动态'
        }
      }, () => {})
    },
    openNewsDetail(item){
      if(item.contentUrl != ''){
        window.open(item.contentUrl);
      }else {
        router.push({
          name: 'NewsDetail',
          query: {
            name: '集团动态',
            id: item.idpk,
          }
        }, () => {})
      }
    },
    updatePausedTrue(){
      this.paused = true
    },
    updatePausedFalse(){
      this.paused = false
    },
    newImg(value){
      for (var i = 0; i < 3; i++){
        this.$set(this.imgShow, i, true)
        this.imgShow[i] = 1
      }
      this.$set(this.imgShow, value, false)
    },
    oldImg(value){
      this.$set(this.imgShow, value, true)
    },
    updateSize(){
      const _this = this
      _this.loadStylesRI = 'margin: auto auto;padding: 0px 0px'
      _this.home = 'margin: auto auto;min-width: 1510px;'
      _this.home1 = 'padding-top: 189px;min-width: 1510px;height: 750px'
      _this.home2 = 'padding-top: 272px;min-width: 1510px;height: 854px'
      _this.content = 'height:340px'
      _this.imgWidth = '400px'
      _this.rotationTextDiv = 'margin: 7.5% 1% 0% 1%'
      _this.rotationText = 'font-size: 16px;'
      _this.rotationText1 = 'margin: 14px 70px 0px 70px;border: 2px solid transparent;'
      _this.rotationText2 = 'line-height: 60px;'
      _this.loadStylesNH = 'padding: 0px 5%'
      if (document.documentElement.clientWidth > 1350) {
        // 选择导航栏一
        _this.loadStylesRI = 'margin: auto auto;padding: 0px 0px'
        _this.loadStylesNH = 'padding: 0px 5%'
        _this.selectNH = 1
      } else if (document.documentElement.clientWidth > 974) {
        // 选择导航栏一
        _this.loadStylesNH = 'padding: 0px 5%'
        _this.loadStylesRI = 'margin: auto auto;padding: 0px 0px'
        _this.selectNH = 1
      } else if (document.documentElement.clientWidth > 600) {
        // 选择导航栏二
        _this.loadStylesNH = 'padding: 0px 5%'
        _this.loadStylesRI = 'margin: auto auto;padding: 0px 0px'
        _this.selectNH = 1
      } else {
        // 选择导航栏二 适配移动端页面
        _this.loadStylesNH = 'padding: 0px 2%'
        _this.loadStylesRI = 'width:280px;height:156px'
        _this.selectNH = 2
        _this.home = 'width: 100%;'
        _this.home1 = 'padding-top: 75px;min-width: 300px;height: 300px;'
        _this.home2 = 'padding-top: 90px;min-width: 300px;height: 230px;'
        _this.content = 'height:78px'
        _this.imgWidth = '90px'
        _this.rotationTextDiv = 'margin: 7.5% 0% 0% 0%'
        _this.rotationText = 'font-size: 12px;margin: 0px 0px'
        _this.rotationText1 = 'margin: 6px 0px 0px 0px;border: 1px solid transparent;'
        _this.rotationText2 = 'line-height: 30px;'
      }
    }
  }
}
</script>

<style scoped>
.home{
  background-color: #ebf0f4;
  width: 1510px;
  margin: auto;
  max-height: 3000px;
}
.home1{
  width: 100%;
  min-width: 1510px;
  height: 750px;
  padding-top: 189px;
  /*border: 1px black solid;*/
  background-image: url("../assets/home/01.jpg");
  background-size:100% 100%;
}
.home2{
  width: 100%;
  min-width: 1510px;
  height: 854px;
  padding-top: 20%;
  /*border: 1px black solid;*/
  background: url("../assets/home/03_YesTitle.jpg") no-repeat;
  background-size:100% 100%;
}
.content{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0px 8%;
  height: 450px;
}
.zxdt{
  color: #71C1E6;
  margin: 14px 55px 0px 55px;
  /*margin: 1% 5% 0px 5%;*/
  border: 2px solid transparent;
  border-radius: 30px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #FFFFFF, #f0f3f8), linear-gradient(to right, #71C1E6, #71C1E6);
}
.more :hover{
  cursor:pointer;
}
.newsClass{
  color: #666666;
  margin-left: 20px;
  text-decoration: none;
  line-height: 60px;
}
.newsClass :hover{
  cursor: pointer;
}

.col-2 {
  padding: 0px 0px;
}

.col-8 {
  padding: 0px 10px;
}

.imgClick:hover{
  cursor: pointer;
}
</style>
