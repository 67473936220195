import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/consult',
    name: 'ServerConsult',
    component: () => import(/* webpackChunkName: "about" */ '../views/server/Consult.vue')
  },
  {
    path: '/base',
    name: 'ServerBase',
    component: () => import(/* webpackChunkName: "about" */ '../views/server/Base.vue')
  },
  {
    path: '/college',
    name: 'ServerCollege',
    component: () => import(/* webpackChunkName: "about" */ '../views/server/College.vue')
  },
  {
    path: '/group',
    name: 'TrendsGroup',
    component: () => import(/* webpackChunkName: "about" */ '../views/trends/Group')
  },
  {
    path: '/us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/Us')
  },
  {
    path: '/newsList',
    name: 'NewsList',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/NewsList')
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/NewsDetail'),
  }
]

const router = new VueRouter({
  routes
})

export default router
