import request from '@/utils/request'

// 查询网站内容列表 (小程序端用)
export function listPortalnews (query) {
  return request({
    url: '/portal/portalnews/list',
    method: 'get',
    params: query
  })
}

// 查询网站内容列表 (PC端用)
export function listPortalnewsAll (query) {
  return request({
    url: '/portal/portalnews/list/all',
    method: 'get',
    params: query
  })
}

// 查询网站内容详细
export function getPortalnews (idpk) {
  return request({
    url: '/portal/portalnews/' + idpk,
    method: 'get'
  })
}

// 新增网站内容
export function addPortalnews (data, query) {
  return request({
    url: '/portal/portalnews' + '?fileUuid=' + query,
    method: 'post',
    data: data
  })
}

// 修改网站内容
export function updatePortalnews (data) {
  return request({
    url: '/portal/portalnews',
    method: 'put',
    data: data
  })
}

// 删除网站内容
export function delPortalnews (idpk) {
  return request({
    url: '/portal/portalnews/' + idpk,
    method: 'delete'
  })
}

// 导出网站内容
export function exportPortalnews (query) {
  return request({
    url: '/portal/portalnews/export',
    method: 'get',
    params: query
  })
}
