<template>
  <div class="foot" >
    <div class="copyright" :style="loadStylesFoot">
          <p style="color:#ccc;font-size:12px">
            Copyright © 2023 浙江云智科创集团有限公司
            <br v-if="loadStylesFoot=='min-width: 1rem;padding-top: 10px;'"/>
            版权所有 <a href="http://beian.miit.gov.cn/">浙ICP备2023017096号-1</a>  
          </p>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',

  props: {
    loadStylesFoot: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>
.foot .copyright {
  height: 70px;
  width: 1510px;
  margin: auto auto;
  /*width: 100%;*/
  background-color: #38538a;
  line-height: 1.25rem;
  color: #38538a;
  padding-top: 1.5rem;
}

.foot p {
  margin-bottom: 0.75rem;
}

.foot a {
  color: #ccc;
  margin-right: 4px;
}
</style>
