<template>
  <div class="Carousel" :style="loadStylesRI">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <!-- Text slides with image -->
          <b-carousel-slide v-for="(item) in imgs" :key="item.id" >
            <template v-slot:img>
              <div style="background: rgba(165, 42, 42, 0)">
                <b-img
                  fluid
                  :src="item.url"
                  alt="banner"
                />
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>

        <!--    <p class="mt-4">-->
        <!--      Slide #: {{ slide }}<br>-->
        <!--      Sliding: {{ sliding }}-->
        <!--    </p>-->
  </div>
</template>

<script>
export default {
  data () {
    return {
      slide: 0,
      sliding: null,
      imgs:[{
        url: require('../assets/home/04_gdp1.png'),
        id: 1
      },{
        url: require('../assets/home/04_gdp2.png'),
        id: 2
      },{
        url: require('../assets/home/04_gdp3.png'),
        id: 3
      },{
        url: require('../assets/home/04_gdp4.png'),
        id: 4
      }]
    }
  },
  props: {
    loadStylesRI: {
      type: String,
      required: true
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  }
}
</script>

<style scoped>
.Carousel{
  width: 700px;
  height: 391px;
  margin: auto auto;
}
img{
  border-radius: 15px
}
.carousel-control-prev{
  padding-right: 110px;
}

#carousel-1 /deep/ ol{
  padding-right: 44%;
  margin-left: 44%;
  margin-right: 0px;
  margin-bottom: 4%;
  height: 3%;
  /*border: 1px solid black;*/
}

#carousel-1 /deep/ ol li{
  display: inline-block;
  width: 9%;
  height: 70%;
  margin-right: 5%;
  border-radius: 50%;
  border: 1px solid black;
  opacity: 0.0;
}
/*隐藏左右切换icon，功能保留*/
/deep/ .carousel-control-prev{
  width: 8%;
  /*height: 100px;*/
}

/deep/ .carousel-control-next{
  width: 8%;
  /*height: 100px;*/
}
/deep/ .carousel-control-prev-icon{
  width: 0px;
  height: 0px;
}
/deep/ .carousel-control-next-icon{
  width: 0px;
  height: 0px;
}
</style>
